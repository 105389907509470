import React from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import Logo from '../assets/imgs/logo-white.png';
import Metamask from '../assets/imgs/Image 1.png';
function Footer() {
	return (
		<div className='footer mt-5'>
			<Container className='d-block d-lg-flex text-center  align-items-center'>
				<img src={Logo} alt='Logo' className='img-fluid logo' />
				<br />
				<div className='ms-auto mt-2 mt-lg-0 d-block d-md-flex align-items-center'>
					<strong className='text-white '>Contract:</strong>
					<Card className='bg-dark text-white ms-3 custom__contract__card'>
						<CardBody>
							<p>0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5</p>
						</CardBody>
					</Card>
					<div className='d-flex align-items-center'>
						<Card className='custom__matamask__card text-dark'>
							<CardBody>
								<strong>Add to MetaMask</strong>
							</CardBody>
						</Card>
						<img
							src={Metamask}
							alt='Meta Mask '
							className='metamask__img'
						/>
					</div>
				</div>
			</Container>
		</div>
	);
}

export default Footer;

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

const ModalLoader = () => {
	const { isOpen, message } = useSelector(state => state.accounts);
	return (
		<>
			<Modal centered isOpen={isOpen}>
				<ModalBody className='bg-dark border2'>
					<div className='text-center justify-content-center align-items-center'>
						<ClipLoader color={'white'} size={100} />
					</div>
					<h5 className='text-center text-white'>{message}</h5>
				</ModalBody>
			</Modal>
		</>
	);
};

export default ModalLoader;

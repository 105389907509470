import React from 'react';
import { Button, Card, CardBody, Container, Table } from 'reactstrap';

function MyNodes({ contract }) {
	return (
		<>
			<Container className='mt-5 pt-4'>
				<h2 className='text-white card__numbers'>My Nodes</h2>
				<Card className='bg-dark mt-4'>
					<CardBody>
						<Table
							className='text-white text-nowrap'
							responsive
							striped
						>
							<thead className=''>
								<tr>
									<th>Name</th>
									<th>Reward Start</th>
									<th>My Rewards</th>
									<th>Fee</th>
									<th>
										<Button className='btn__claim text-dark '>
											Pay all fees
										</Button>
									</th>
									<th>
										<Button className='btn__claim text-dark '>
											Claim all
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{new Array(5).fill(1).map((_, idx) => {
									return (
										<tr key={idx}>
											<td>Node 1</td>
											<td>24-Apr-2020</td>
											<td>0.463254</td>
											<td>due in 63 days</td>
											<td>
												<Button className=' text-dark btn__claim'>
													Pay fees
												</Button>
											</td>
											<td>
												<Button className=' text-dark btn__claim'>
													Claim
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default MyNodes;

import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Row,
} from 'reactstrap';
import GC from '../assets/imgs/1.png';
import Reward from '../assets/imgs/rewards.png';
import Trophy from '../assets/imgs/trophy.png';
import StockMarket from '../assets/imgs/stock-market.png';
import Wallet from '../assets/imgs/wallet.png';
import Dollor from '../assets/imgs/dollar.png';
import Nodes from '../assets/imgs/nodes.png';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/Modal';
import {
	buyWithAvax,
	buyWithGreenToken,
	getDividends,
	claimRewards,
} from '../store/actions';
import { toast } from 'react-toastify';
function ProfileDetails({ contract }) {
	const dispatch = useDispatch();
	const [amount, setAmount] = useState();
	const [investType, setInvestType] = useState('');
	const { account, greenPrice, avaxPrice, dailyReward, totalReward } =
		useSelector(state => state.accounts);
	const handleBuyToken = async e => {
		e.preventDefault();
		if (account && investType) {
			if (investType === 'avax') {
				dispatch(buyWithAvax(contract, account, avaxPrice, amount));
			} else {
				dispatch(
					buyWithGreenToken(contract, account, greenPrice, amount)
				);
			}
		} else {
			toast.warn('Please Connect with Account!!!', { autoClose: 1000 });
		}
	};
	const claimRewardToken = async () => {
		if (account) {
			dispatch(claimRewards(contract, account));
		} else {
			toast.error('Please Connect with Account!!!', { autoClose: 1000 });
		}
	};
	return (
		<div className='profile__details mt-5'>
			<Modal />
			<Container>
				<Row>
					<Col xl='8'>
						<h5 className='text-white rise__from'>
							RISE FROM THE ASHES OF TRADITIONAL FINANCE
						</h5>
						<h2 className='text__primary app__dashboard'>
							App Dashboard
						</h2>
						<h4 className='text-white your__profile'>
							Your Profile Details
						</h4>
						<Row className='mt-4'>
							<Col lg='4' md='6'>
								<Card className='bg-dark'>
									<CardBody className='d-flex'>
										<div>
											<strong className='text-white card__title'>
												My Nodes
											</strong>
											<h3 className='text__primary card__numbers'>
												0/100
											</h3>
											<small className='text-muted card__tagline'>
												All nests: 81883
											</small>
										</div>
										<div className='icon__img ms-auto'>
											<img
												src={GC}
												alt='Green Crypto'
												className='img-fluid  '
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg='4' md='6' className='mt-3 mt-md-0'>
								<Card className='bg-success'>
									<CardBody className='d-flex'>
										<div>
											<strong className='text-white card__title text-nowrap'>
												Daily Rewards
											</strong>
											<h3 className='text-dark card__numbers'>
												{/* $60.250 */}
												{dailyReward}
												<br />
												GREEN
											</h3>
											<small className='text-white card__tagline'>
												per Node
											</small>
										</div>
										<div className='icon_img ms-auto'>
											<img
												src={Reward}
												alt='Reward'
												className='img-fluid'
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg='4' md='6' className='mt-3 mt-lg-0'>
								<Card className='bg-dark'>
									<CardBody className='d-flex'>
										<div>
											<strong className='text-white card__title'>
												My Rewards
											</strong>
											<h3 className='text__primary card__numbers'>
												{/* $60.250 */}
												{totalReward}
												<br />
												GREEN
											</h3>
										</div>
										<div className='icon_img ms-auto'>
											<img
												src={Trophy}
												alt='Trophy'
												className='img-fluid'
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg='4' md='6' className='mt-3'>
								<Card className='bg-dark'>
									<CardBody className='d-flex'>
										<div>
											<strong className='text-white card__title'>
												Trade
											</strong>
											<h3 className='text__primary card__numbers'>
												JohnDoe
											</h3>
										</div>
										<div className='icon_img ms-auto'>
											<img
												src={StockMarket}
												alt='StockMarket'
												className='img-fluid'
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg='4' md='6' className='mt-3'>
								<Card className='bg-dark'>
									<CardBody className='d-flex'>
										<div>
											<strong className='text-white card__title'>
												Treasury Balance
											</strong>
											<h3 className='text__primary card__numbers'>
												$60.250
											</h3>
										</div>
										<div className='icon_img ms-auto'>
											<img
												src={Wallet}
												alt='Wallet'
												className='img-fluid'
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col lg='4' md='6' className='mt-3'>
								<Card className='bg-dark'>
									<CardBody className='d-flex'>
										<div>
											<strong className='text-white card__title'>
												Total Investments
											</strong>
											<h3 className='text__primary card__numbers'>
												$60.250
											</h3>
										</div>
										<div className='icon_img ms-auto'>
											<img
												src={Dollor}
												alt='Dollor'
												className='img-fluid'
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Col>
					<Col xl='4' className='mt-3 mt-xl-0 align-self-end'>
						<Card className='bg-dark'>
							<CardBody>
								<h3 className='text__primary card__numbers'>
									Create a Node with 100 $Green tokens to earn
									rewards.
								</h3>
								<p className='text-muted card__desc'>
									You can use the rewards to create more Nodes
									and grow your holding to a maximum of 100
									Nests per wallet.
								</p>
								<Form onSubmit={handleBuyToken}>
									<Input
										placeholder='Enter Amount'
										value={amount}
										required
										onChange={e =>
											setAmount(e.target.value)
										}
									/>
									<div className='text-center mt-3 d-flex '>
										<Button
											type='submit'
											onClick={() =>
												setInvestType('avax')
											}
											block
											className='btn__connect py-3 m-1'
										>
											Buy With Avax
										</Button>
										<Button
											type='submit'
											onClick={() =>
												setInvestType('green')
											}
											block
											className='btn__connect py-3 m-1'
										>
											Buy With Green
										</Button>
									</div>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Card className='bg-dark mt-5'>
					<CardBody>
						<div className='d-block d-lg-flex'>
							<h3 className='text-white card__numbers'>
								Create a Node with 100 $Green tokens to earn
								rewards.
							</h3>
							<Button
								onClick={claimRewardToken}
								// block
								className='w-25 btn__connect py-3 ms-auto d-none d-lg-block'
								// className='btn__claim text-dark '
							>
								Claim all
							</Button>
							<Button
								onClick={claimRewardToken}
								block
								className='w-25 btn__connect py-3 ms-auto d-block d-lg-none'
								// className='btn__claim text-dark '
							>
								Claim all
							</Button>
						</div>
						<p className=' mt-3 mt-lg-0 text-white card__tagline'>
							Each Node will earn{' '}
							<span className='text__primary'>0.5479</span> every
							24 hours
						</p>
						<img src={Nodes} alt='Nodes' className='img-fluid' />
					</CardBody>
				</Card>
			</Container>
		</div>
	);
}

export default ProfileDetails;

import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { toast } from 'react-toastify';
import GreenToken from '../../contracts/Green.sol/GREEN.json';
import { GreenAddress, NodeDappAddress } from '../../utils/address';
const RPC =
	'https://api.avax.network/ext/bc/C/rpc';
const chainID = 43114;
// export let web3 = new Web3(RPC);
export let web3 = new Web3(Web3.givenProvider || RPC);
export const connectAccount = () => async dispatch => {
	try {
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider,
				options: {
					bridge: 'https://bridge.walletconnect.org',
					chainId: chainID, //137 for mainnet
					// network: 'av', //matic for mainnet
					// network: "mumbai", //matic for test net
					rpc: {
						// 1: RPC,
						43114: RPC,
						// 80001: "https://polygon-mumbai.g.alchemy.com/v2/zItQRWHRPhns60Fn6m4mLuU08pPjvZrV",
					},
				},
			},
		};
		const web3Modal = new Web3Modal({
			// network: 'mainnet', //matic for mainnet
			network: 'rinkeby', //matic for mainnet
			cacheProvider: false, // optional
			providerOptions, // required
			disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
		});

		console.log('Web3Modal instance is', web3Modal);
		const provider = await web3Modal.connect();
		web3 = new Web3(provider);
		dispatch({ type: 'ADD_WEB3', payload: provider });
		console.log('Web3 instance is', web3, provider);
		const chainid = await web3.eth.getChainId();
		console.log(chainid);
		if (chainid !== chainID)
			return toast.error('Please connect with Rinkeby network!', {
				autoClose: 1000,
			});
		const accounts = await web3.eth.getAccounts();
		console.log(accounts);
		console.log('provider', await provider, await provider.connected);
		dispatch({ type: 'GET_ACCOUNT', payload: accounts[0] });
		if (accounts[0])
			toast.success('wallet connected successfully!', {
				autoClose: 1000,
			});
		provider.on('accountsChanged', accounts => {
			console.log('Account changed', accounts[0]);
			dispatch({ type: 'GET_ACCOUNT', payload: accounts[0] });
		});
	} catch (err) {
		console.log(err);
	}
};

export const getGreenTokenPrice = contract => async dispatch => {
	const response = await contract.methods.NODE_PRICE_IN_GREEN().call();
	const valueInEther = Number(web3.utils.fromWei(response, 'ether'));
	dispatch({ type: 'GET_GREEN_TOKEN_PRICE', payload: valueInEther });
};
export const getAvaxPrice = contract => async dispatch => {
	const response = await contract.methods.NODE_PRICE_IN_AVAX().call();
	const valueInEther = Number(web3.utils.fromWei(response, 'ether'));
	dispatch({ type: 'GET_AVAX_PRICE', payload: valueInEther });
};

export const buyWithAvax =
	(contract, account, avaxPrice, amount) => async dispatch => {
		dispatch(
			modalState(true, 'Please Wait While Transaction is processed!!!')
		);
		const totalPrice = Number(avaxPrice * amount).toFixed(5);
		const totalPriceInWei = web3.utils.toWei(totalPrice, 'ether');
		console.log('account', account);
		const response = await contract.methods
			.buyTokenByAvax()
			.send({ from: account, value: totalPriceInWei })
			.on('receipt', receipt => {
				if (receipt['transactionHash']) {
					console.log('receipt', receipt['transactionHash']);
					dispatch(modalState(false, ''));
					return receipt['transactionHash'];
				}
			})
			.on('error', error => {
				toast.error(error.message);
				dispatch(modalState(false, ''));
				return error;
			});
		return response;
	};
export const buyWithGreenToken =
	(contract, account, greenPrice, amount) => async dispatch => {
		dispatch(
			modalState(true, 'Please Wait While Transaction is processed!!!')
		);
		const totalPrice = Number(greenPrice * amount).toFixed(5);
		const totalPriceInWei = web3.utils.toWei(totalPrice, 'ether');
		const greenContract = new web3.eth.Contract(
			GreenToken.abi,
			GreenAddress
		);
		const balance = await greenContract.methods
			.balanceOf(account)
			.call();
		
		const decimals = await greenContract.methods.decimals().call();
		console.log("token balance", balance, (Number(balance) / (10 ** Number(decimals))));
		const approveResponse = await greenContract.methods
			.approve(NodeDappAddress, totalPriceInWei)
			.send({ from: account })
			.on('error', err => {
				console.log('error', err);
				toast.error(err.message);
				dispatch(modalState(false, ''));
			});
		if (approveResponse) {
			const response = await contract.methods
				.buyTokenByGreen(totalPriceInWei)
				.send({ from: account })
				.on('receipt', receipt => {
					if (receipt['transactionHash']) {
						console.log('receipt', receipt['transactionHash']);
						dispatch(modalState(false, ''));
						return receipt['transactionHash'];
					}
				})
				.on('error', error => {
					toast.error(error.message);
					dispatch(modalState(false, ''));
					return error;
				});
		} else {
			return;
		}
		return approveResponse;
	};

export const claimRewards = (contract, account) => async dispatch => {
	dispatch(modalState(true, 'Please Wait While Transaction is processed!!!'));
	web3.eth.handleRevert = true;
	const response = await contract.methods
		.claimReward()
		.send({ from: account })
		.on('error', err => {
			toast.error(err.message);
			dispatch(modalState(false, ''));
		});
	if (response) {
		console.log(response);
		dispatch(modalState(false, ''));
	}
};
export const getDividends = (contract, account) => async dispatch => {
	// dispatch(modalState(true, 'Please Wait While Transaction is processed!!!'));
	const response = await contract.methods.getDividends(account).call();
	const priceInEther = web3.utils.fromWei(response, 'ether');
	if (response) {
		dispatch({ type: 'GET_REWARD', payload: Number(priceInEther) });
		// dispatch(modalState(false, ''));
	}
};
export const dailyReward = contract => async dispatch => {
	// dispatch(modalState(true, 'Please Wait While Transaction is processed!!!'));
	const response = await contract.methods.REWARD_PER_SECOND().call();
	const priceInEther = Number(web3.utils.fromWei(response, 'ether'));
	const perDay = Number(priceInEther * 86400).toFixed(3);
	if (response) {
		dispatch({ type: 'DAILY_REWARD', payload: Number(perDay) });
		// dispatch(modalState(false, ''));
	}
};



// export const myNodes = (contract,account) => async dispatch => {
	
// }

export const modalState = (isOpen, message) => async dispatch => {
	dispatch({ type: 'MODAL_STATE', payload: { isOpen, message } });
};

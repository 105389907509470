import { useEffect, useState } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import MyNodes from './components/MyNodes';
import ProfileDetails from './components/ProfileDetails';
import NodeDapp from './contracts/NodeDapp.sol/NodeDapp.json';
import { NodeDappAddress } from './utils/address';
import {
	dailyReward,
	getAvaxPrice,
	getDividends,
	getGreenTokenPrice,
	web3,
} from './store/actions';
import { useDispatch, useSelector } from 'react-redux';

function App() {
	const [contract, setContract] = useState('');
	const dispatch = useDispatch();
	const { account } = useSelector(state => state.accounts);
	const getContractData = async () => {
		let newContract = new web3.eth.Contract(NodeDapp.abi, NodeDappAddress);
		setContract(newContract);
		dispatch(getGreenTokenPrice(newContract));
		dispatch(getAvaxPrice(newContract));
		dispatch(dailyReward(newContract));
	};
	const getUserRewards = async account => {
		dispatch(getDividends(contract, account));
	};
	useEffect(() => {
		getContractData();
	}, []);

	useEffect(() => {
		if (!account) return;
		getUserRewards(account);
	}, [account]);
	return (
		<div className='App'>
			<Header contract={contract} />
			<ProfileDetails contract={contract} />
			{/* <MyNodes contract={contract} /> */}
			<Footer />
		</div>
	);
}

export default App;

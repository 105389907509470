import React, { useState } from 'react';
import {
	Navbar,
	Nav,
	NavbarBrand,
	NavbarToggler,
	Collapse,
	NavItem,
	NavLink,
	Button,
} from 'reactstrap';
import Logo from '../assets/imgs/logo.png';
import { connectAccount } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';

function Header({ contract }) {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const { account } = useSelector(state => state.accounts);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	const connect = () => {
		dispatch(connectAccount());
	};
	return (
		<>
			<Navbar expand='md' className='px-5'>
				<NavbarBrand href='/'>
					<img src={Logo} alt='Logo' className='img-fluid' />
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse navbar isOpen={isOpen}>
					<Nav className='ms-auto' navbar>
						<NavItem>
							<NavLink href='/'>Home</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='/'>Profile Details</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href='/'>My Nodes</NavLink>
						</NavItem>
						<NavItem className='ms-2'>
							<Button
								onClick={connect}
								disabled={account}
								className='btn__connect'
							>
								{account
									? account.substr(0, 5) +
									  '...' +
									  account.substr(account.length - 5)
									: 'Connect Wallet'}
							</Button>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</>
	);
}

export default Header;

const initialState = {
	account: null,
	web3Provider: null,
	isOpen: false,
	message: '',
	greenPrice: 0,
	avaxPrice: 0,
	totalReward: 0,
	dailyReward: 0,
};
const accountReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_ACCOUNT':
			return {
				...state,
				account: action.payload,
			};
		case 'ADD_WEB3':
			return {
				...state,
				web3Provider: action.payload,
			};
		case 'GET_GREEN_TOKEN_PRICE':
			return {
				...state,
				greenPrice: action.payload,
			};
		case 'GET_AVAX_PRICE':
			return {
				...state,
				avaxPrice: action.payload,
			};
		case 'GET_REWARD':
			return {
				...state,
				totalReward: action.payload,
			};
		case 'DAILY_REWARD':
			return {
				...state,
				dailyReward: action.payload,
			};

		case 'DISCONNECT':
			return {
				...state,
				account: null,
			};
		case 'MODAL_STATE':
			return {
				...state,
				isOpen: action.payload.isOpen,
				message: action.payload.message,
			};
		default:
			return state;
	}
};

export default accountReducer;
